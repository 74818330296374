import { gql } from '@apollo/client';

// add Article categories
export const ADD_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_add($createDTO: GroupCreateDTO!) {
    admin_community_group_add(createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;

// update Article categories
export const UPDATE_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_update($groupId: String!, $createDTO: GroupCreateDTO!) {
    admin_community_group_update(groupId: $groupId, createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;

export const ADD_COMMUNITY_POST = gql`
  mutation Community_post_add($createDTO: PostCreateDTO!) {
    community_post_add(createDTO: $createDTO) {
      id
      deleted
      active
      groupId
      title
      slug
      language
      isPinned
      isAnonymous
      stats {
        viewCount
        postCount
        commentCount
        reactionCount
      }
    }
  }
`;

export const UPDATE_COMMUNITY_POST = gql`
  mutation Community_post_update($postId: String!, $createDTO: PostCreateDTO!) {
    community_post_update(postId: $postId, createDTO: $createDTO) {
      id
      deleted
      active
      groupId
      title
      slug
      language
      isPinned
      isAnonymous
      stats {
        viewCount
        commentCount
        reactionCount
      }
    }
  }
`;

export const DELETE_COMMUNITY_POST = gql`
  mutation Community_post_remove($postId: String!) {
    community_post_remove(postId: $postId) 
  }
`;
