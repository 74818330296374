import Loader from 'components/Loader';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { ACTION_TAKEN_ARRAY, ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Paginate from '../../components/Paginate';
import { checkIfValueExist, formatInputDate, printDateTimeTwoLines, printSerialNumbers } from '../../utils/helper';

const HelpCenterList = () => {
  const history = useHistory();
  const router = useRouter();
  toast.configure();

  const [activeTab, setActiveTab] = useState('open');
  const tabsList = [
    {
      name: 'Open',
      filters: {
        isClosed: false,
        deleted: false,
      },
    },
    {
      name: 'Closed',
      filters: {
        isClosed: true,
        deleted: false,
      },
    },
    {
      name: 'Deleted',
      filters: {
        deleted: true,
      },
    },
    {
      name: 'All',
      filters: {},
    },
  ];

  const [inquiryList, setInquiryList] = useState([]);
  const [inquiryPagination, setInquiryPagination] = useState({});

  const [deleteAppEnquiryRequest, { loading: deleteAppEnquiryLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      const currentTabFilter = tabsList?.find((tab) => tab.name?.toLowerCase() === activeTab?.toLowerCase());
      handleChangePage(1, currentTabFilter?.filters);
      toast.error('App Enquiry has been deleted');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [markClosedAppEnquiryRequest, { loading: markClosedAppEnquiryLoading }] = useRequest({
    method: 'PUT',
    onSuccess: () => {
      const currentTabFilter = tabsList?.find((tab) => tab.name?.toLowerCase() === activeTab?.toLowerCase());
      handleChangePage(1, currentTabFilter?.filters);
      toast.error('App Enquiry has been closed');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getContactInquiryListRequest, { loading: getContactInquiryListLoading }] = useRequest({
    method: 'POST',
    url: '/common/app-inquiry/admin/search',
    onSuccess: (data) => {
      setInquiryList(data?.results);
      setInquiryPagination(data?.pagination);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const defaultFilters = {
    isClosed: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: formatInputDate(moment().subtract(1, 'year')),
    endDate: formatInputDate(moment()),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page, tabFilters) => {
    setPageInUrl(page);
    getContactInquiryListRequest({
      body: {
        page,
        size: 50,
        ...filters,
        sortBy: 'updatedDate',
        sortOrder: -1,
        isClosed: checkIfValueExist(filters.isClosed?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        ...tabFilters,
      },
    });
  };

  const handleMarkClosed = async (data) => {
    const result = await confirm({
      title: 'Mark Action Taken',
      message: 'Are you sure, you want to mark action taken on this Inquiry?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      markClosedAppEnquiryRequest({
        url: `/common/app-inquiry/admin/${data?.id}/mark-closed`,
      });
    }
  };

  const handleDeleteInquiry = async (data) => {
    const result = await confirm({
      title: 'Delete Inquiry',
      message: 'Are you sure, you want to delete this Inquiry?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      deleteAppEnquiryRequest({
        url: `/common/app-inquiry/admin/${data?.id}`,
      });
    }
  };

  const applyFilters = () => {
    const currentTabFilter = tabsList?.find((tab) => tab.name?.toLowerCase() === activeTab?.toLowerCase());
    handleChangePage(parseInt(router?.query?.page, 10) || 1, currentTabFilter?.filters);
  };

  const clearFilters = () => {
    const currentTabFilter = tabsList?.find((tab) => tab.name?.toLowerCase() === activeTab?.toLowerCase());

    setFilters({
      ...defaultFilters,
    });
    getContactInquiryListRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        sortBy: 'updatedDate',
        sortOrder: -1,
        isClosed: checkIfValueExist(defaultFilters.isClosed?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        ...currentTabFilter?.filters,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <Loader isActive={getContactInquiryListLoading || deleteAppEnquiryLoading || markClosedAppEnquiryLoading} />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="App Tickets List" />

          <div>
            <Nav tabs>
              {tabsList?.map((elem, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab?.toLowerCase() === elem?.name?.toLowerCase() ? 'active' : ''}
                      onClick={() => {
                        setActiveTab(elem?.name?.toLowerCase());
                        handleChangePage(1, elem?.filters);
                      }}>
                      {elem?.name}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <Card>
                  <CardBody>
                    <div className="mt-4">
                      <div className="row">
                        {/* <div className="col-lg-2 mb-4">
                          <label className="form-label">is Closed</label>
                          <Select
                            options={ACTION_TAKEN_ARRAY}
                            value={filters.isClosed}
                            onChange={(value) => setFilters({ ...filters, isClosed: value })}
                          />
                        </div>
                        <div className="col-lg-2 mb-4">
                          <label className="form-label">Deleted</label>
                          <Select
                            options={ACTIVE_AND_DELETED_ARRAY}
                            value={filters.deleted}
                            onChange={(value) => setFilters({ ...filters, deleted: value })}
                          />
                        </div> */}

                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Created Date - From</label>
                          <Input
                            type="date"
                            name="startDate"
                            placeholder="From Date"
                            value={filters.startDate}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setFilters({ ...filters, startDate: formatInputDate(e.target.value) })}
                          />
                        </div>
                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Created Date - To</label>
                          <Input
                            type="date"
                            name="endDate"
                            placeholder="To Date"
                            value={filters.endDate}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setFilters({ ...filters, endDate: formatInputDate(e.target.value) })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <a className="btn btn-primary me-4" onClick={applyFilters}>
                        Apply Filters
                      </a>
                      <a className="" onClick={clearFilters}>
                        Clear
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </div>

          {inquiryPagination && (
            <Row className="mb-2">
              <Col sm="4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative font-bold">{inquiryPagination?.totalCount} Tickets Found</div>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12">
              <Col lg="12">
                <div className="">
                  <div className="table-responsive" style={{ minHeight: 300 }}>
                    <Table className="project-list-table table-centered table-borderless">
                      <thead>
                        <tr className="table-warning">
                          {' '}
                          <th scope="col">CreatedBy</th>
                          <th scope="col">Subject </th>
                          <th scope="col">Issue Details</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Updated Date</th>
                          <th scope="col">isClosed</th>
                          <th scope="col">Approved</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(inquiryList) ? (
                          inquiryList?.map((data, index) => (
                            <tr
                              className={data?.isClosed ? 'bg-success' : data?.deleted ? 'bg-danger' : ''}
                              key={index}>
                              <td>
                                <a href={`/users/profile/${data?.createdById}`}>{data?.createdById}</a>
                                <br />
                                {data?.email}
                              </td>
                              <td>
                                <a href={`/help-center/conversations?id=${data?.id}`}>{data.title}</a>
                              </td>
                              <td>{data?.content}</td>
                              <td className="text-nowrap">{printDateTimeTwoLines(data.createdDate)}</td>
                              <td className="text-nowrap">{printDateTimeTwoLines(data.updatedDate)}</td>
                              <td>
                                <Badge className={data.isClosed ? 'bg-success' : 'bg-danger'}>
                                  {data.isClosed ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data.approved ? 'bg-success' : 'bg-danger'}>
                                  {data.approved ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                {(!data?.isClosed || !data?.deleted) && (
                                  <UncontrolledDropdown>
                                    <DropdownToggle href="#" className="card-drop" tag="i">
                                      <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      {!data?.isClosed && (
                                        <DropdownItem onClick={() => handleMarkClosed(data)}>
                                          <i className="fas fa-pencil-alt text-success me-2 fa-fw" />
                                          Mark Closed
                                        </DropdownItem>
                                      )}
                                      {!data?.deleted && (
                                        <DropdownItem onClick={() => handleDeleteInquiry(data)}>
                                          <i className="fas fa-trash-alt text-danger me-2 fa-fw" />
                                          Delete Enquiry
                                        </DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <TableError />
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>

          <Paginate pageInfo={inquiryPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default HelpCenterList;
