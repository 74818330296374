import { useLazyQuery } from '@apollo/client';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Table } from 'reactstrap';
import { SORT_VALUES } from 'utils/constants';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import { useRouter } from '../../../hooks/useRouter';
import { printDateTime, printSerialNumbers } from '../../../utils/helper';
import { COMMUNITY_REPORTED_POSTS_SEARCH } from '../graphql/community.query';

const CommunityReportedreportedPosts = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [reportedPosts, setReportedreportedPosts] = useState([]);
  const [reportedPostsPagination, setReportedPostsPagination] = useState({});

  const [getReportedreportedPostsRequest, { loading: getReortedreportedPostsLoading }] = useLazyQuery(
    COMMUNITY_REPORTED_POSTS_SEARCH,
    {
      onCompleted: (data) => {
        if (!isEmpty(data?.admin_community_post_report_search)) {
          setReportedreportedPosts(data?.admin_community_post_report_search?.results);
          setReportedPostsPagination(data?.admin_community_post_report_search?.pagination);
        }
      },
      onError: () => {
        toast.error('Something went wrong!');
      },
    }
  );

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = async (page) => {
    setPageInUrl(page);
    await getReportedreportedPostsRequest({
      variables: {
        searchDTO: {
          page,
          size: 20,

          ...SORT_VALUES,
        },
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getReortedreportedPostsLoading} />

        <Container fluid>
          <Breadcrumbs title="Community" breadcrumbItem="Community Reported Posts list" />

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {reportedPostsPagination?.totalCount} Reported Posts found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="table-responsive" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col">S.No.</th>

                      <th scope="col">CreatedBy</th>
                      <th scope="col">Post Title</th>
                      <th scope="col">Reason</th>
                      <th scope="col">Comments</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Updated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(reportedPosts) ? (
                      reportedPosts?.map((elem, index) => {
                        return (
                          <tr key={`post_${elem?.id}`} className={!elem?.active ? 'bg-danger' : ''}>
                            <td>{printSerialNumbers(reportedPostsPagination)[index]}</td>
                            <td>{elem?.createdBy?.displayName}</td>
                            <td>
                              <a
                                target="_blank"
                                href={process.env.REACT_APP_THEPARENTZ_WEB_URL + `/community/post/${elem?.post?.id}`}>
                                {elem?.post?.title}
                              </a>
                            </td>
                            <td>{elem?.reason}</td>
                            <td>{elem?.comments}</td>
                            <td>{printSerialNumbers(reportedPostsPagination)[index]}</td>
                            <td>{printDateTime(elem?.createdDate)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={reportedPostsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CommunityReportedreportedPosts;
