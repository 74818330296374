import Loader from 'components/Loader';
import config from 'config/config';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Table } from 'reactstrap';
import { api } from 'utils/axios';
import { SCHOOL_REVIEWER_TYPE, USER_SOURCE } from 'utils/constants';
import * as Yup from 'yup';

const FetchReviews = ({ schoolDetail, schoolFormik }) => {
  const [reviewsData, setReviewsData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function generateEmail(firstName) {
    const modifiedFirstName = firstName.toLowerCase().replace(/\s/g, '');

    const timestamp = Date.now().toString();
    const email = `${modifiedFirstName}${timestamp}@smk.com`;

    return email;
  }
  function generateRandomPassword(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  function generateRandomPhoneNumbers() {
    const randomSuffix = Math.floor(Math.random() * 100000000)
      .toString()
      .padStart(8, '0');
    return `91${randomSuffix}`;
  }

  const addReviews = async (reviews) => {
    setIsLoading(true);
    const limit = 5;

    for (let i = 0; i < reviews.length && i < limit; i++) {
      let payload = {
        firstName: reviews[i]?.user?.name?.split(' ')?.[0],
        lastName: reviews[i]?.user?.name?.split(' ')?.[1],
        email: generateEmail(reviews[i]?.user?.name),
        password: generateRandomPassword(12),
        source: USER_SOURCE.REVIEW,
      };

      await api({
        method: 'POST',
        url: config.apiEndpoint + '/user/admin',
        data: payload,
      })
        .then(async (response) => {
          console.log(response);
          let review_payload = {
            active: false,
            school: {
              id: schoolDetail?.id,
            },
            rating: reviews[i]?.rating,
            text: reviews[i]?.snippet,
            title: reviews[i]?.snippet?.slice(0, 20) + '...',
            createdBy: { id: response?.id },
            reviewerType: SCHOOL_REVIEWER_TYPE.OTHERS,
            // keepAnonymous: true,
          };

          await api({
            method: 'POST',
            url: config.apiEndpoint + '/school/admin/review',
            data: review_payload,
          })
            .then((res) => {
              setIsLoading(false);
              toast.success('Reviews and school are added successfully.');
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error);
            });
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      googleMapsUrl: '',
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      setReviewsData('');
      await fetch(`${process.env.REACT_APP_SCHOOL_DATA_EXTRACTOR_URL}/puppeteer?url=${values.googleMapsUrl}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          const res = await response?.json();
          if (res.status === 200) {
            setReviewsData(res.data);
            if (!isEmpty(res?.data?.reviews)) {
              addReviews(res?.data?.reviews);
            }
          } else {
            toast.error('An unexpected error occurred while scraping the data');
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      googleMapsUrl: Yup.string()
        .required('URL is required')
        .test('validate', 'Enter a valid google map url', (val) => {
          if (
            !val.trim().includes('https://www.google.com/maps/place/') &&
            !val.trim().includes('https://www.google.co.in/maps/place/') &&
            !val.trim().includes('https://goo.gl/maps')
          ) {
            return false;
          }
          return true;
        }),
    }),
  });

  return (
    <div>
      <Loader isActive={isLoading} />

      <Form onSubmit={formik.handleSubmit}>
        <Input
          rows={5}
          id="googleMapsUrl"
          type="textarea"
          className="form-control"
          placeholder="Google maps url"
          value={schoolFormik?.values?.googleMapsUrl}
          onChange={(e) => {
            schoolFormik?.setFieldValue('googleMapsUrl', e.target.value);
            formik?.setFieldValue('googleMapsUrl', e.target.value);
          }}
          invalid={!!(formik.touched.googleMapsUrl && formik.errors.googleMapsUrl)}
        />
        <div className="d-flex justify-content-between mt-4">
          <p>
            {!isEmpty(schoolDetail?.systemInfo?.lastReviewFetchedOn) ? (
              <>
                <b>Last Review Fetch:</b>
                <span>{moment(schoolDetail?.systemInfo?.lastReviewFetchedOn).format('DD-MMM-YYYY @ hh:mm a')}</span>
              </>
            ) : (
              ''
            )}
          </p>
          <button className="btn btn-light" type="submit">
            Fetch Reviews
          </button>
        </div>
      </Form>

      {!isEmpty(reviewsData) && (
        <div className="table-responsive" style={{ minHeight: 300 }}>
          <Table
            className="project-list-table mt-4  table-centered table-striped border-top"
            style={{ borderSpacing: 0 }}>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Rating</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {reviewsData?.reviews?.map((review, index) => (
                <tr key={index}>
                  <td>
                    {!isEmpty(review?.user?.thumbnail) ? (
                      <img src={review?.user?.thumbnail} width={40} height={40} />
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{review?.user?.name}</td>
                  <td>{review?.rating}</td>
                  <td>{review?.date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default FetchReviews;
