import React, { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import CustomImageUploader from './components/editor/editorImageUploader';
import simpleImageUmd from '@editorjs/simple-image';
import Underline from '@editorjs/underline';
import Strikethrough from 'editorjs-strikethrough';
import Columns from '@calumk/editorjs-columns';
import Paragraph from '@editorjs/paragraph';
import Embed from '@editorjs/embed';
import Marker from '@editorjs/marker';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import RawTool from '@editorjs/raw';
import Hyperlink from './customHyperlink/Hyperlink';

const ContentEditor = ({ editorInstance, forType }) => {
  const editorRef = useRef(null);

  const tools_list = {
    header: {
      class: Header,
      inlineToolbar: ['bold', 'italic', 'hyperlink', 'marker', 'underline', 'strikethrough'],
      tunes: ['anyTuneName'],
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: ['bold', 'italic', 'hyperlink', 'marker', 'underline', 'strikethrough'],
      tunes: ['anyTuneName'],
      toolbox: {
        title: 'Paragraph',
      },
    },
    anyTuneName: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
        blocks: {
          header: 'left',
          list: 'left',
        },
      },
    },
    raw: RawTool,
    hyperlink: {
      class: Hyperlink,
      inlineToolbar: true,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer', 'nofollow'],
        validate: false,
      },
    },
    marker: {
      class: Marker,
      shortcut: 'CMD+SHIFT+M',
    },
    embed: {
      class: Embed,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    list: {
      class: NestedList,
      inlineToolbar: true,
      tunes: ['anyTuneName'],
      config: {
        defaultStyle: 'ordered',
      },
    },
    table: {
      class: Table,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    delimiter: {
      tunes: ['anyTuneName'],
      class: Delimiter,
    },
    quote: {
      class: Quote,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    image: {
      class: simpleImageUmd,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    underline: {
      class: Underline,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    strikethrough: {
      class: Strikethrough,
      tunes: ['anyTuneName'],
      shortcut: 'CMD+SHIFT+X',
      inlineToolbar: true,
    },
    customBlock: {
      class: CustomImageUploader,
      tunes: ['anyTuneName'],
      inlineToolbar: false,
      config: {
        forType,
      },
    },
  };

  useEffect(() => {
    if (!editorRef.current) {
      editorInstance.current = new EditorJS({
        holder: 'editorjs_editor',
        autofocus: true,
        placeholder: 'Let`s write an awesome story!',
        tools: {
          ...tools_list,
          columns: {
            class: Columns,
            config: {
              EditorJsLibrary: EditorJS,
              tools: {
                ...tools_list,
              },
            },
          },
        },
      });
    }

    return () => {
      if (editorRef.current && typeof editorRef.current.destroy === 'function') {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, []);

  return <div className="py-4 ps-5" id="editorjs_editor"></div>;
};

export default ContentEditor;
