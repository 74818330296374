import { useLazyQuery, useMutation } from '@apollo/client';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import TableError from 'components/TableError';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledDropdown,
    Badge,
    DropdownItem
} from 'reactstrap';
import { buildURL, showImage } from 'utils/helper';
import { COMMUNITY_GROUPS_GET_BY_ID, COMMUNITY_MEMBER_SEARCH, COMMUNITY_POSTS_SEARCH, COMMUNITY_REPORTED_POSTS_SEARCH } from '../graphql/community.query';
import { SMKLinks } from 'utils/links';
import { Link, useHistory } from 'react-router-dom';
import { PRINT_LANGUAGE, PRINT_LANGUAGE_COLOR } from 'utils/constants';
import { DELETE_COMMUNITY_POST, UPDATE_COMMUNITY_POST } from '../graphql/community.mutation';

const CommunityGroupDetails = () => {
    const { query } = useRouter();
    toast.configure();

    const tabsList = ['Contributors', 'Post', 'Reported Posts'];

    const [activeTab, setActiveTab] = useState('1');
    const [groupDetails, setGroupDetails] = useState({});

    const [membersList, setMembersList] = useState([]);
    const [postsList, setPostsList] = useState([]);
    const [reportedPostList, setReportedPostList] = useState([]);

    const [getCommunityDetails, { loading: getCommunityDetailsLoading }] = useLazyQuery(COMMUNITY_GROUPS_GET_BY_ID, {
        onCompleted: (data) => {
            if (!isEmpty(data?.admin_community_group_getById)) {
                setGroupDetails(data?.admin_community_group_getById);
            }
        },
        onError: () => {
            toast.error('Something went wrong!');
        },
    });

    const [getMembersRequest, { loading: communityMembersRequestLoading }] = useLazyQuery(COMMUNITY_MEMBER_SEARCH, {
        onCompleted: (data) => {
            if (!isEmpty(data?.community_member_search)) {
                setMembersList(data?.community_member_search);
            }
        },
        onError: () => {
            toast.error('Something went wrong!');
        },
    });

    const [getPostsRequest, { loading: getPostsRequestLoading }] = useLazyQuery(COMMUNITY_POSTS_SEARCH, {
        onCompleted: (data) => {
            if (!isEmpty(data?.community_post_search)) {
                setPostsList(data?.community_post_search);
            }
        },
        onError: () => {
            toast.error('Something went wrong!');
        },
    });

    const [markPostActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useMutation(UPDATE_COMMUNITY_POST, {
        onCompleted: (data) => {
            if (!isEmpty(data?.admin_community_post_update)) {
                handleChangePage(parseInt(router?.query?.page, 10) || 1);
                toast.success(
                    `Success! Community Post has been ${data?.admin_community_post_update?.active ? 'active' : 'Inactive'}.`
                );
            }
        },
        onError: (error) => {
            toast.error('Something went wrong!');
        },
    });

    const [markPostDeleted, { loading: markDeletedLoading }] = useMutation(DELETE_COMMUNITY_POST, {
        onCompleted: (data) => {
            if (!isEmpty(data?.community_post_remove)) {
                handleChangePage(parseInt(router?.query?.page, 10) || 1);
                toast.success(
                    `Success! Community Post has been ${data?.community_post_remove?.success === 'true' ? 'deleted' : 'Undeleted'}.`
                );
            }
        },
        onError: (error) => {
            toast.error('Something went wrong!');
        },
    });

    const [getReportedPostsRequest, { loading: reportedPostsLoading }] = useLazyQuery(COMMUNITY_REPORTED_POSTS_SEARCH, {
        onCompleted: (data) => {
            if (!isEmpty(data?.admin_community_post_report_search)) {
                setReportedPostList(data?.admin_community_post_report_search);
            }
        },
        onError: () => {
            toast.error('Something went wrong!');
        },
    });

    const handlePostActive = (data) => {
        // toggle post active
        markPostActiveInActiveRequest({
            variables: {
                postId: data?.id,
                createDTO: {
                    ...data,
                },
            },
        });
    }

    const handlePostDelete = async (data) => {
        const result = await confirm({
            title: `${data?.deleted ? 'Undelete' : 'Delete'} Group`,
            message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Post?`,
            confirmText: 'Confirm',
            confirmColor: 'primary',
            cancelColor: 'link text-danger',
        });
        if (result) {
            await markPostDeleted({
                variables: {
                    postId: data?.id,
                },
            });
        }
    }

    const miniCards = [
        {
            title: 'Posts',
            text: `${groupDetails?.stats?.postCount} `,
        },
        {
            title: 'Comments',
            text: `${groupDetails?.stats?.commentCount}`,
        },
        {
            title: 'Reactions',
            text: `${groupDetails?.stats?.reactionCount}`,
        }
    ];

    useEffect(() => {
        getCommunityDetails({
            variables: {
                groupId: query?.id,
            },
        });
        getMembersRequest({
            variables: {
                searchDTO: {
                    page: 1,
                    size: 20,
                    sortBy: 'id',
                    sortOrder: 1,
                    groupId: query?.id,
                },
            },
        });
        getPostsRequest({
            variables: {
                searchDTO: {
                    page: 1,
                    size: 20,
                    sortBy: 'id',
                    sortOrder: 1,
                    groupId: query?.id,
                },
            },
        });

        getReportedPostsRequest({
            variables: {
                searchDTO: {
                    page: 1,
                    size: 20,
                    sortBy: 'id',
                    sortOrder: 1
                },
            },
        });

    }, []);

    const showContributorsList = () => {
        return (
            <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                        <tr className="table-warning">
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                            {/* <th scope="col"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(membersList?.results) ? (
                            membersList?.results?.map((item, index) => {
                                return (
                                    <tr key={`_test_${item?.memeber?.displayName}_`} className={!item?.active ? 'bg-danger' : ''}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item?.member?.displayName}
                                        </td>
                                        <td>
                                            <Badge className={item?.active ? 'bg-success' : 'bg-danger'}>{item?.active ? 'Yes' : 'No'}</Badge>
                                        </td>
                                        <td>
                                            <Badge className={item?.deleted ? 'bg-success' : 'bg-danger'}>
                                                {item?.deleted ? 'Yes' : 'No'}
                                            </Badge>
                                        </td>
                                        {/* <td>
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link
                            to={buildURL(SMKLinks.COMMUNITY_MEMBERS_EDIT, {
                              id: item?.id,
                            })}>
                            <DropdownItem>
                              <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                              Edit
                            </DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td> */}
                                    </tr>
                                );
                            })
                        ) : (
                            <TableError />
                        )}
                    </tbody>
                </Table>
            </div>
        );
    };

    const showPostList = () => {
        return (
            <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                        <tr className="table-warning">
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Language</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(postsList?.results) ? (
                            postsList?.results?.map((post, index) => {
                                return (
                                    <tr key={`_test_${post?.name}_`} className={!post?.active ? 'bg-danger' : ''}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {
                                                <Link
                                                    to={buildURL(SMKLinks.COMMUNITY_GROUPS_DETAILS, {
                                                        id: post?.id,
                                                    })}>
                                                    {post?.name}
                                                </Link>
                                            }
                                            <br />
                                            {post?.slug}
                                        </td>
                                        <td>
                                            <Badge className={PRINT_LANGUAGE_COLOR[post?.language]}>{PRINT_LANGUAGE[post?.language]}</Badge>
                                        </td>
                                        <td>
                                            <Badge className={post?.active ? 'bg-success' : 'bg-danger'}>{post?.active ? 'Yes' : 'No'}</Badge>
                                        </td>
                                        <td>
                                            <Badge className={post?.deleted ? 'bg-success' : 'bg-danger'}>
                                                {post?.deleted ? 'Yes' : 'No'}
                                            </Badge>
                                        </td>
                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle href="#" className="card-drop" tag="i">
                                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <Link
                                                        to={buildURL(SMKLinks.COMMUNITY_POSTS_EDIT, {
                                                            id: post?.id,
                                                        })}>
                                                        <DropdownItem>
                                                            <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                                                            Edit
                                                        </DropdownItem>
                                                    </Link>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <TableError />
                        )}
                    </tbody>
                </Table>
            </div>
        );
    };

    const showReportedPosts = () => {
        return (
            <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                        <tr className="table-warning">
                            <th scope="col">S.No.</th>
                            <th scope="col">Post</th>
                            <th scope="col">Comments</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(reportedPostList?.results) ? (
                            reportedPostList?.results?.map((item, index) => {
                                return (
                                    <tr key={`_test_${item?.id}_`} className={!item?.active ? 'bg-danger' : ''}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {
                                                <Link
                                                    to={buildURL(SMKLinks.COMMUNITY_POSTS_EDIT, {
                                                        id: item?.post?.d,
                                                    })}>
                                                    {item?.post?.title}
                                                    <br />
                                                    {item?.post?.id}
                                                </Link>
                                            }
                                        </td>
                                        <td>
                                            {item.comments}
                                        </td>
                                        <td>
                                            {item.reason}
                                        </td>
                                        <td>
                                            {item.createdDate}
                                        </td>
                                        <td>
                                            <Badge className={item?.active ? 'bg-success' : 'bg-danger'}>{item?.post?.active ? 'Yes' : 'No'}</Badge>
                                        </td>
                                        <td>
                                            <Badge className={item?.deleted ? 'bg-success' : 'bg-danger'}>
                                                {item?.post?.deleted ? 'Yes' : 'No'}
                                            </Badge>
                                        </td>
                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle href="#" className="card-drop" tag="i">
                                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem onClick={() => handlePostActive(item?.post)}>
                                                        <i
                                                            className={`fas  ${item?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                                                }   m-1 pe-3`}
                                                        />
                                                        {item?.active ? 'Disable' : 'Enable'}
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handlePostDelete(item?.post)}>
                                                        <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                                        {item?.deleted ? 'Undelete' : 'Delete'}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <TableError />
                        )}
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <div className="page-content">
            <Loader isActive={getCommunityDetailsLoading || getPostsRequestLoading || communityMembersRequestLoading} />

            <Container fluid>
                <Breadcrumbs title="Community Group" breadcrumbItem="Community Group Details" />

                <Row>
                    <Col xl="4">
                        <Card className="overflow-hidden">
                            <CardBody className="p-3">
                                <Row className="align-items-center">
                                    <Col sm="3">
                                        <div className="avatar-md profile-user-wid m-auto d-flex align-items-center justify-content-center">
                                            {!isEmpty(groupDetails?.image) ? (
                                                <img src={showImage(groupDetails?.image?.original)} className="img-thumbnail rounded-circle" />
                                            ) : (
                                                <i className="bx bxs-message-square-detail" style={{ fontSize: '50px', color: '#3460BB' }} />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm="9">
                                        <h5 className="font-size-15 text-truncate mb-1">{groupDetails?.name}</h5>
                                        <p className="text-muted mb-0 text-truncate">{groupDetails?.slug}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl="8">
                        <Row>
                            {map(miniCards, (card, key) => (
                                <MiniCards
                                    mdCol={3}
                                    title={card?.title}
                                    text={card?.text}
                                    textColor={card?.textColor}
                                    iconClass={card?.iconClass}
                                    key={`_card_${key}`}
                                    removeIcon={true}
                                />
                            ))}
                        </Row>
                    </Col>

                    <div>
                        <Nav tabs>
                            {tabsList?.map((tab, index) => (
                                <NavItem key={`_test_${index}`}>
                                    <NavLink
                                        className={activeTab === (index + 1).toString() ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab((index + 1).toString());
                                        }}>
                                        {tab}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">{showContributorsList()}</TabPane>
                            <TabPane tabId="2">{showPostList()}</TabPane>
                            <TabPane tabId="3">{showReportedPosts()}</TabPane>
                        </TabContent>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default CommunityGroupDetails;
