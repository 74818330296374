import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { SMKLinks } from '../../../utils/links';
import { ADD_COMMUNITY_POST, UPDATE_COMMUNITY_POST } from '../graphql/community.mutation';
import { COMMUNITY_POSTS_SEARCH } from '../graphql/community.query';

const AddUpdatePosts = () => {
  const history = useHistory();
  const { id: categoryId } = useParams();

  toast.configure();

  const [postList, setPostList] = useState();
  const [postDetails, setPostDetails] = useState();

  const [postListRequest, { loading: postListLoading }] = useLazyQuery(COMMUNITY_POSTS_SEARCH, {
    onCompleted: (data) => {
      if (!isEmpty(data?.community_post_search)) {
        setPostList(data?.community_post_search?.results);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [addCategoryRequest, { loading: addPostLoading }] = useMutation(ADD_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.community_post_add)) {
        toast.success(`Success! Post ( ${data?.id} ) has been added.`);
        history.replace(SMKLinks.COMMUNITY_REPORTED_POSTS_LIST);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [updateCategoryRequest, { loading: updatePostLoading }] = useMutation(UPDATE_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.community_post_update)) {
        toast.success(`Success! Post ( ${data?.id} ) has been updated.`);
        window.location.reload();
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const editInitialValues = () => {
    return {
      id: postDetails?.id,
      active: postDetails?.active,
      deleted: postDetails?.deleted,
      isPinned: postDetails?.isPinned,
      isAnonymous: postDetails?.isAnonymous,

      name: postDetails?.name,
      slug: postDetails?.slug,
      content: postDetails?.content,
      categories: postDetails?.categories,

      image: postDetails?.image || {},
      coverImage: postDetails?.coverImage || {},
      language: LANGUAGE_ARRAY_WITH_STRING.find((t) => t.label.toLowerCase() === postDetails?.language.toLowerCase())
        .value,
      // translations: !isEmpty(postDetails?.translations) ? postDetails?.translations[0] : {},
      seoTitle: postDetails?.seo?.title || '',
      seoDescription: postDetails?.seo?.content || '',
      seoKeywords: !isEmpty(postDetails?.seo?.keywords)
        ? postDetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: postDetails?.seo?.ogImage || {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isPinned: false,
    isAnonymous: false,
    name: '',
    slug: '',
    content: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    categories: null,

    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    ogImage: {},

    image: {},
    coverImage: {},
    // translations: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        isPinned: values?.isPinned,
        isAnonymous: values?.isAnonymous,
        name: values?.name,
        slug: values?.slug,
        content: values?.content,
        categories: !isEmpty(values?.categories) ? values?.categories?.map((e) => ({ id: e?.id })) : null,
        language: values?.language,
        // translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
          content: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null, image: undefined }),
        ...(!isEmpty(values?.coverImage)
          ? {
              coverImageId: values?.coverImage?.id,
              coverImage: undefined,
            }
          : { coverImageId: null, coverImage: undefined }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
      };

      isEmpty(categoryId)
        ? addCategoryRequest({
            variables: {
              createDTO: {
                ...data,
              },
            },
          })
        : updateCategoryRequest({
            variables: {
              postId: categoryId,
              createDTO: {
                ...data,
              },
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(postDetails)) {
      formik.setValues(editInitialValues());
    }
  }, [postDetails]);

  return (
    <div className="page-content">
      <Loader isActive={addPostLoading || updatePostLoading || postListLoading} />
      <Container fluid>
        <Breadcrumbs
          title="Community Post"
          breadcrumbItem={`${isEmpty(categoryId) ? 'Add' : 'Edit'}  Community Post`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={categoryId}
                        nameValue={formik.values.name}
                        formPostClassName={'mt-0'}
                        showResetRegenerate={true}
                        currentSlug={postDetails?.slug}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="content" className="form-label  ">
                          Content
                        </Label>
                        <Input
                          id="content"
                          name="content"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Content.."
                          invalid={!!(formik.touched.content && formik.errors.content)}
                          {...formik.getFieldProps('content')}
                        />
                        <FormFeedback>{formik.errors.content}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {categoryId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.COMMUNITY_REPORTED_POSTS_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['active', 'deleted', 'isPinned', 'isAnonymous']?.map((elem, index) => (
                    <FormGroup className={`mb-3`} key={index}>
                      <Label className="mb-3">
                        {elem === 'isPinned'
                          ? 'isAnonymous Post Allowed'
                          : elem === 'isAnonymous'
                          ? 'isAnonymous Comment Allowed'
                          : titleCaseIfExists(elem)}
                      </Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdatePosts;
